import React from "react"
import { Global, css } from "@emotion/react"
import styled from "@emotion/styled"
import facepaint from 'facepaint'
import { theme } from "./theme"
import highlightYellow from "../images/highlight-yellow.svg"
import highlightPurple from "../images/highlight-purple.svg"
import letterIcon from "../images/letter-icon.png"
import phoneIcon from "../images/phone-icon.png"
import menuBurger from "../images/menu-burger.png"
import menuClose from "../images/menu-close.png"
import arrowRight from "../images/arrow-right.png"
import blogBackground from "../images/blog-background.jpg"
import { Header } from "./header"
import { useSiteMetadata } from "../hooks/use-site-metadata";

const breakpoints = theme.breakpoints

const mq = facepaint(
  breakpoints.map(bp => `@media (min-width: ${bp}px)`)
)

const Wrapper = styled("div")`
  margin: 0 auto;
`

export default function Layout({ children, location }) {
  const { title } = useSiteMetadata();

  return (
    <Wrapper css={mq({
      maxWidth: ['100%', '556px', '728px', '932px']
    })}
    className='wrapper'>
      <Global
        styles={css`
          body {
            color: ${theme.bodyColor};
            background: ${theme.backgroundColor};
            font-family: "${theme.sansSerifFont}";
            line-height: ${theme.lineHeightRegular};
            padding: 1em 1em 6em;
            @media (min-width: ${breakpoints[0]}px) {
              font-size: ${theme.regular[0]};
            }
            @media (min-width: ${breakpoints[2]}px) {
              font-size: ${theme.regular[1]};
            }
            &.page-reflektioner {
              background-image: url(${blogBackground});
              background-repeat: no-repeat;
              background-position: top center;
              overflow-x: hidden;
              position: relative;
              &:after {
                content: "Bakgrundsbilden är ett manipulerat foto av Alex Perez på Unsplash";
                bottom: 15em;
                display: block;
                font-size: 0.78em;
                opacity: 0.5;
                position: absolute;
                right: -14.5em;
                transform: rotate(-90deg);
                z-index: -1;
                @media (min-width: ${breakpoints[2]}px) {
                  padding-left: 1em;
                }
              }
            }
          }
          .wrapper > header {
            text-align: center;
            .konfront-logo {
              margin: 0 auto;
            }
            .link-home {
              img {
                width: 183px;
              }
            }
          }
          nav button {
            background-color: transparent;
            background-image: url(${menuBurger});
            background-repeat: no-repeat;
            border: none;
            height: 22px;
            overflow: hidden;
            position: absolute;
            right: 2em;
            top: 2em;
            transition:  .2s;
            transition-property: opacity;
            width: 33px;
            z-index: 2222;
            &:hover {
              cursor: pointer;
              opacity: 0.8;
            }
            &.menu-open {
              background-image: url(${menuClose});
            }
            span {
              display: block;
              position: absolute;
              top: -50px;
            }
          }
          h1 {
            font-family: "${theme.serifFont}";
            line-height: ${theme.lineHeightLarge};
            margin: 3em 0 0;
            @media (min-width: ${breakpoints[0]}px) {
              font-size: ${theme.large[0]};
              margin: 2em 0 0;
            }
            @media (min-width: ${breakpoints[2]}px) {
              font-size: ${theme.large[1]};
            }
            em {
              font-style: italic;
              font-weight: normal;
            }
          }
          small,
          .heading-intro {
            color: ${theme.lightGray};
            font-size: ${theme.superscript[0]};
            font-weight: bold;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            @media (min-width: ${breakpoints[2]}px) {
              font-size: ${theme.superscript[1]};
            }
          }
          .heading-intro {
            margin: 8em 0 0;
            @media (min-width: ${breakpoints[2]}px) {
              margin-top: 12em;
            }
          }
          .page-bubbles-of-bliss h1 + .heading-intro {
            margin: 2em 0 8em;
            text-align: center;
            @media (min-width: ${breakpoints[2]}px) {
              margin-bottom: 12em;
            }
          }
          .page-intro {
            font-family: "${theme.serifFont}";
            font-style: italic;
            text-align: center;
          }
          h2 {
            font-family: "${theme.serifFont}";
            line-height: ${theme.lineHeightMedium};
            font-weight: normal;
            margin: 0 0 1em 0;
            @media (min-width: ${breakpoints[0]}px) {
              font-size: ${theme.medium[0]};
            }
            @media (min-width: ${breakpoints[2]}px) {
              font-size: ${theme.medium[1]};
            }
          }
          p {
            @media (min-width: ${breakpoints[0]}px) {
              margin-left: 8vw;
              margin-right: 8vw;
            }
            @media (min-width: ${breakpoints[1]}px) {
              margin-left: 10vw;
              margin-right: 10vw;
            }
            &.centered {
              text-align: center;
            }
            strong {
              font-weight: 600;
            }
          }
          .p-intro {
            font-size: 0.85em;
            font-weight: 700;
            letter-spacing: 0.02em;
            text-transform: uppercase;
          }

          article ol,
          article ul {
            @media (min-width: ${breakpoints[0]}px) {
              list-style-position: outside;
              margin-left: 6vw;
              margin-right: 6vw;
              padding-left: 0;
            }
            @media (min-width: ${breakpoints[1]}px) {
              margin-left: 8vw;
              margin-right: 8vw;
            }
            li {
              padding-left: 2vw;
              margin-top: 1em;
              @media (min-width: ${breakpoints[1]}px) {
                padding-left: 2vw;
              }
              &::marker {
                font-family: "${theme.serifFont}";
                font-size: 2em;
                font-weight: 700;
              }
            }
          }
          .body-factbox {
            background-image: url(${highlightYellow});
            background-position: top 3vw center;
            background-repeat: no-repeat;
            background-size: 130%;
            margin-top: 0;
            padding: 12vw 2vw;
            text-align: center;
            @media (min-width: ${breakpoints[0]}px) {
              background-position: top 2vw center;
              background-size: 100%;
              padding: 6vw 3.5vw 12vw;
            }
            @media (min-width: ${breakpoints[1]}px) {
              background-size: 80%;
              padding: 6vw 9vw 12vw;
            }
            @media (min-width: ${breakpoints[2]}px) {
              background-position: top 2.5vw center;
              padding: 5vw 8vw 12vw;
            }
            @media (min-width: 1470px) {
              background-color: ${theme.yellow};
              background-position: top 2.5vw center;
              margin-bottom: 4vw;
              padding: 2vw;
            }
          }
          .button-load-more {
            background-color: transparent;
            background-image: url(${highlightYellow});
            background-position: top 0.5em center;
            background-repeat: no-repeat;
            background-size: 130%;
            border: none;
            cursor: pointer;
            display: block;
            font-family: "${theme.sansSerifFont}";
            font-size: 1em;
            font-weight: 700;
            margin-left: auto;
            margin-right: auto;
            margin-top: 0;
            padding: 2em;
          }
          a,
          .button-load-more {
            color: ${theme.bodyColor};
            transition:  .2s;
            transition-property: opacity;
            &:hover,
            &:hover > * {
              opacity: 0.8;
            }
          }
          .highlight {
            background-position: center;
            background-repeat: no-repeat;
            background-size: 90%;
            margin: -4% 8% -3%;
            padding: 6% 8% 4%;
            &.inline-block {
              margin: -2% 0;
              padding: 2%;
            }
            &.yellow {
              background-image: url(${highlightYellow});
            }
            &.purple {
              background-image: url(${highlightPurple});
            }
            .page-bubbles-of-bliss & {
              background-position: center 10px;
              @media (min-width: ${breakpoints[2]}px) {
                background-position: center 20px;
              }
            }
          }
          .inline-block {
            background-size: 100%;
            display: inline-block;
            .page-bubbles-of-bliss & {
              background-size: 180%;
            }
          }

          .sofia-image {
            float: left;
            margin-right: 0.5em;
            height: 200px;
            width: 200px;
            @media (min-width: ${breakpoints[1]}px) {
              margin-right: 1em;
            }
            @media (min-width: ${breakpoints[1]}px) {
              height: 350px;
              margin-right: 2em;
              width: 350px;
            }
            img {
              height: 100%;
              width: 100%;
            }
          }
          .familj-image {
            margin: 1em auto;
            width: 100%;
            > picture,
            > img,
            > picture img {
                margin: 0 auto;
                height: 263px;
                width: 350px;
            }
          }
          .factbox-container,
          .resume-container,
          .bob-container {
            width: 100vw;
            position: relative;
            margin-left: -50vw;
            margin-top: 100px;
            left: 50%;
          }
          .factbox-container {
            background: ${theme.green};
          }
          .resume-container {
            background: ${theme.yellow};
          }
          .bob-container {
            background: ${theme.purple};
            color: ${theme.lightBodyColor};
            a {
              color: ${theme.lightBodyColor};
            }
          }

          .factbox,
          .resume,
          .bubbles-of-bliss {
            margin: 4em auto;
            padding: 2em 1em;
            @media (min-width: ${breakpoints[0]}px) {
              margin-left: auto;
              margin-right: auto;
              max-width: 100%;
            }
            @media (min-width: ${breakpoints[1]}px) {
              max-width: 556px;
            }
            @media (min-width: ${breakpoints[2]}px) {
              padding-bottom: 4em;
              padding-top: 4em;
              max-width: 728px;
            }
            @media (min-width: ${breakpoints[1]}px) {
              max-width: 932px;
            }
          }
          .factbox,
          .resume,
          .bliss-wrapper {
            font-family: "${theme.monoFont}";
            line-height: ${theme.lineHeightMono};
            & > * {
              margin-left: 4%;
              margin-right: 4%;
            }
            @media (min-width: ${breakpoints[0]}px) {
              font-size: ${theme.monoRegular[0]};
            }
            @media (min-width: ${breakpoints[2]}px) {
              font-size: ${theme.monoRegular[1]};
            }
            h2 {
              font-family: "${theme.monoFont}";
              font-weight: bold;
              text-transform: uppercase;
              font-size: ${theme.monoHeading[0]};
              @media (min-width: ${breakpoints[2]}px) {
                font-size: ${theme.monoHeading[1]};
              }
            }
          }
          .factbox {
            ul.columns {
              columns: 2;
              -webkit-columns: 2;
              -moz-columns: 2;
              column-gap: 3em;
              padding: 0;
              @media (min-width: ${breakpoints[1]}px) {
                column-gap: 6em;
                column-rule: 2px dotted ${theme.bodyColor};
                list-style-position: inside;
              }
              li {
                margin-bottom: 0.5em;
              }
            }
          }
          .resume {
            .col > * {
              @media (min-width: ${breakpoints[0]}px) {
                margin-right: 3rem;
              }
            }
          }
          .bob-fact {
            margin-bottom: 2em;
            .col {
              margin-top: 2em;
              @media (min-width: ${breakpoints[0]}px) {
                margin-top: 0;
              }
            }
            .col-1 > * {
              @media (min-width: ${breakpoints[0]}px) {
                margin-right: 3rem;
              }
            }
            .col-2 > * {
              @media (min-width: ${breakpoints[0]}px) {
                margin-right: 0rem; /* Compensation for margin-left defined elsewhere */
              }
            }
          }
          /* Script för att animera när objektet syns: https://coolcssanimation.com/how-to-trigger-a-css-animation-on-scroll/ */
          .bliss-wrapper {
            > p:first-of-type {
              position: relative;
              height: 2em;
              &:before {
                content: "När du befinner dig i ett tillstånd av bliss känner du dig:";
                display: block;
                position: absolute;
                top: 0;
                width: 100%;
              }
              &:after {
                content: "Men istället finns det risk att du känner dig:";
                display: block;
                opacity: 0;
                text-align: right;
                position: absolute;
                top: 0;
                width: 100%;
              }
            }
            .animation-row {
              display: grid;
              grid-template-columns: min-content 3fr min-content;
              .parameters {
                p {
                  margin: 0 auto;
                  text-transform: uppercase;
                }
              }
              .parameters-left {
                color: ${theme.yellow};
              }
              .parameters-center {
                  > p {
                    position: relative;
                    transform: translateX(50%);
                    span {
                      display: inline-block;
                      transform: translateX(-50%);
                    }
                    &:before {
                      content: " ";
                      display: inline-block;
                      border-bottom: 1px solid #fff;
                      bottom: -0.5em;
                      left: -100%;
                      margin-right: 5em;
                      position: relative;
                      float: right;
                      width: 0;
                    }
                    &:after {
                      content: " ";
                      display: inline-block;
                      border-bottom: 1px solid #fff;
                      bottom: 0.8em;
                      margin-left: 2em;
                      left: 6em;
                      position: absolute;
                      width: 0;
                    }
                  }
              }
              .parameters-right {
                color: ${theme.yellow};
                opacity: 0;
                text-align: right;
              }
            }
            &.is-visible {
              > p:first-of-type {
                &:before {
                  animation: fadeout 2s 4s ease-in 1 forwards;
                }
                &:after {
                  /* transition: all 1s 2s ease; */
                  animation: fadein 2s 6s ease-in 1 forwards;
                }
              }
              .animation-row {
                /* transition: all 1s 2s ease; */
              }
              .parameters-left {
                /* transition: all 1s ease; */
                width: 150px;
                opacity: 0;
                /* animation: shrinkleft 2s 2s ease-in 2 alternate; */
                animation-name: shrinkleft, shrinkright, fadein;
                animation-duration: 4s, 4s, 2s;
                animation-timing-function: ease-in, ease-in, ease-in;
                animation-delay: 2s, 7s, 10s;
                animation-iteration-count: 1, 1, 1;
                /* animation-direction: alternate, alternate; */
                animation-fill-mode: forwards, forwards, forwards;
              }
              .parameters-center {
                /* transition: all 1s 1s ease; */
                > p {
                  /* transition: all 1s ease; */
                  /* animation: goPleft 4s 2s ease-in  1 forwards; */
                  animation-name: goPleft, goPright;
                  animation-duration: 4s, 4s;
                  animation-timing-function: ease-in, ease-in;
                  animation-delay: 2s, 7s;
                  animation-iteration-count: 1, 1;
                  animation-fill-mode: forwards, forwards;
                  span {
                    /* animation: goSpanleft 4s 2s ease-in 1 forwards; */
                    animation-name: goSpanleft, goSpanright;
                    animation-duration: 4s, 4s;
                    animation-timing-function: ease-in, ease-in;
                    animation-delay: 2s, 7s;
                    animation-iteration-count: 1, 1;
                    animation-fill-mode: forwards, forwards;
                  }
                  &:before {
                    animation: drawlineShort 2s 10s ease-in 2 alternate;
                  }
                  &:after {
                    animation: drawline 2s 6s ease-in 2 alternate;
                  }
                }
              }
              .parameters-right {
                /* transition: all 1s 2.5s ease;
                opacity: 1; */
                animation: fadein 2s 8s ease 1 forwards;
              }
            }
          }
          @keyframes shrinkleft {
            0% {
              opacity: 0;
              width: 150px;
            }
            75%, 100% {
              opacity: 0;
              width: 0;
            }
          }
          @keyframes shrinkright {
            0%, 25% {
              opacity: 0;
              width: 0;
            }
            100% {
              opacity: 0;
              width: 150px;
            }
          }
          @keyframes goPleft {
            0% {
              transform: translateX(50%);
            }
            75%, 100% {
              transform: translateX(0%);
            }
          }
          @keyframes goSpanleft {
            0% {
              transform: translateX(-50%);
            }
            75%, 100% {
              transform: translateX(0%);
            }
          }
          @keyframes goPright {
            0%, 25% {
              transform: translateX(0%);
            }
            100% {
              transform: translateX(50%);
            }
          }
          @keyframes goSpanright {
            0%, 25% {
              transform: translateX(0%);
            }
            100% {
              transform: translateX(-50%);
            }
          }
          @keyframes fadein {
            0% {
              opacity: 0;
            }
            75%, 100% {
              opacity: 1;
            }
          }
          @keyframes fadeout {
            0%, 25% {
              opacity: 1;
            }
            100% {
              opacity: 0;
            }
          }
          @keyframes drawline {
            0% {
              width: 0;
            }
            50%, 100% {
              width: 39vw;
            }
          }
          @keyframes drawlineShort {
            0% {
              width: 0;
            }
            25%, 100% {
              width: 14vw;
            }
          }
          .resume {
            padding-top: 2em;

            h2 {
              font-family: "${theme.sansSerifFont}";
              font-size: 1.33em;
              font-weight: 100;
              letter-spacing: 0.02em;
              margin-bottom: 0.8em;
              margin-top: 2em;
              &+hr {
                border: none;
                margin: 0;
                overflow: hidden;
                &:before {
                  content:".......................................................................";
                  letter-spacing: 0; /* Use letter-spacing to increase space between dots*/
                }
              }
            }
            h3 {
              font-size: 1.22em;
              font-family: "${theme.serifFont}";
              letter-spacing: 0.01em;
              margin-bottom: 0.3em;
              margin-top: 0.3em;
            }
            p {
              font-size: 0.78em;
              margin-bottom: 0;
              margin-left: 0;
              margin-top: 0.8em;
              &.super-header {
                margin-top: 2.5em;
                letter-spacing: 0.05em;
                text-transform: uppercase;
              }
            }
            figure {
              font-family: "${theme.serifFont}";
              font-weight: 700;
              margin: 3em auto 4em;
              blockquote {
                font-style: italic;
                margin-inline-start: 1em;
                margin-inline-end: 1em;
                text-align: center;
                @media (min-width: ${breakpoints[1]}px) {
                  margin-inline-start: 2em;
                  margin-inline-end: 2em;
                }
                & + figcaption {
                  margin-top: -0.78em;
                  text-align: center;
                }
              }
              figcaption {
                font-family: "${theme.sansSerifFont}";
                font-size: 0.78em;
                font-weight: 300;
              }
            }
          }
          .bubbles-of-bliss {
            .heading-intro {
              color: ${theme.lightBodyColor};
              display: block;
              margin-top: 4em;
              opacity: 0.8;
              overflow: hidden;
              &:before {
                content:".....................................................................................................................................................................................................................";
                display: block;
                letter-spacing: 0.3em; /* Use letter-spacing to increase space between dots*/
                padding-bottom: 8em;
              }
              &:first-of-type {
                margin-top: 0;
                &:before {
                  content:"";
                }
              }
            }
            h2 {
              font-family: "${theme.sansSerifFont}";
              font-weight: 700;
            }
            p {
              line-height: 120%;
              margin-top: 1.5em;
            }
            ul {
              line-height: 120%;
              list-style-type: disc;
              margin-top: 1em;
            }
            > ul {
              @media (min-width: ${breakpoints[0]}px) {
                margin-left: 8vw;
                margin-right: 8vw;
              }
              @media (min-width: ${breakpoints[1]}px) {
                margin-left: 10vw;
                margin-right: 10vw;
              }
            }
            ol.timeline {
              margin: 2.5em 0;
              padding: 0;
              display: inline-block;
            }

            ol.timeline {
              > li {
                line-height: 120%;
                list-style: none;
                margin: auto;
                margin-left: 2vw;
                margin-right: -2vw;
                min-height: 50px;
                border-left: 1px dashed #fff;
                padding: 0 10vw 50px 8vw;
                position: relative;
                &:last-child {
                  border-left: 0;
                }
                &::before {
                  position: absolute;
                  left: -18px;
                  top: 0;
                  content: " ";
                  border: 2px solid rgba(255, 255, 255, 1);
                  border-radius: 500%;
                  background: ${theme.purple};
                  font-weight: 600;
                  height: 30px;
                  width: 30px;
                  text-align: center;
                  transition: all 500ms ease-in-out;
                  line-height: 180%;
                  @media (min-width: ${breakpoints[0]}px) {
                    line-height: 150%;
                  }
                  @media (min-width: ${breakpoints[2]}px) {
                    line-height: 130%;
                  }
                }
                &:nth-child(1)::before {
                  content: '1';
                }
                &:nth-child(2)::before {
                  content: '2';
                }
                &:nth-child(3)::before {
                  content: '3';
                }
                &:nth-child(4)::before {
                  content: '4';
                }
              }
            }

            .bob-fact {
              overflow: hidden;
              p, h3 {
                font-family: "${theme.monoFont}";
                font-size: ${theme.monoRegular[0]};
                margin-left: 0;
                @media (min-width: ${breakpoints[2]}px) {
                  font-size: ${theme.monoRegular[1]};
                }
              }
              h3 {
                line-height: 120%;
                margin-bottom: 0;
                text-transform: uppercase;
                + p {
                  margin-top: 0;
                }
              }
              .col-2 {
                h3, p {
                  @media (min-width: ${breakpoints[0]}px) {
                    margin-left: 3rem;
                  }
                }
              }
              &:before,
              &:after {
                content:".....................................................................................................................................................................................................................";
                letter-spacing: 0.1em; /* Use letter-spacing to increase space between dots*/
              }
            }
          }
          .bubbles-of-bliss + .bob-image {
            margin-top: -6em; /* eliminates .bubbles-of-bliss margin: 4em auto; padding-top: 2em 1em; */
          }
          .bob-image figure {
            @media (min-width: ${breakpoints[1]}px) {
              align-items: end;
              display: grid;
              grid-template-columns: 35% 65%; /*columns widths*/
              margin: 0;
            }
            figcaption {
              border-top: 1px solid ${theme.lightBodyColor};
              font-size: ${theme.monoHeading};
              line-height: ${theme.lineHeightMono};
              margin-bottom: 2em;
              padding-top: 1em;
              @media (min-width: ${breakpoints[0]}px) {
                margin: 2em;
              }
            }
          }

          .bob-image + .bubbles-of-bliss .heading-intro:first-of-type:before {
            content:".....................................................................................................................................................................................................................";
            display: block;
            letter-spacing: 0.3em; /* Use letter-spacing to increase space between dots*/
            padding-bottom: 8em;
          }

          .year {
            font-weight: 600;
          }
          div.columns {
            position: relative;
            &:after {
              /* Clear floats after the columns */
              content: "";
              display: table;
              clear: both;
            }
            .col {
              margin-left: 0;
              margin-right: 0;
              @media (min-width: ${breakpoints[0]}px) {
                float: left;
                width: 50%;
              }
            }
          }
          .list-blog-post {
            margin-top: 2em;
            a {
              text-decoration: none;
            }
            p {
              margin-bottom: 0;
            }
            small {
              font-weight: 700;
            }
            .arrow-right {
              float: right;
              margin: 1em 0;
            }
            &:after {
              /* Clear floats after the columns */
              content: "";
              display: table;
              clear: both;
            }
          }
          .blog-post-wrapper {
            li {
              p {
                margin-left: 0;
                margin-right: 0;
              }
              > h2, h3 {
                font-family: "${theme.serifFont}";
                font-weight: 700;
              }
              &::marker {
                font-size: 1.2em;
              }
            }
            small {
              display: block;
              text-align: center;
            }
            .navigation-links {
              margin-top: 3em;
              a {
                display: inline-block;
                font-weight: bold;
                text-decoration: none;
                &:after {
                  content: url(${arrowRight});
                  display: block;
                  margin-top: 0.78em;
                  transition:  .2s;
                  transition-property: opacity;
                }
                &:hover:after {
                  opacity: 0.8;
                }
                &.link-prev {
                  float: left;
                  text-align: right;
                  &:after {
                    transform: scale(-1, 1)
                  }
                }
                &.link-next {
                  float: right;
                  &:after {
                    text-align: right;
                  }
                }
              }
              &:after {
                /* Clear floats after the columns */
                content: "";
                display: table;
                clear: both;
              }
            }
          }
          .list-blog-post:first-of-type,
          .blog-post-wrapper > p:first-of-type {
            margin-top: 4em;
            @media (min-width: ${breakpoints[2]}px) {
              margin-top: 6em;
            }
          }
          .contactbox-container {
            background-image: url(${highlightYellow});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            /* margin: 0 8% -3%; */
            /* padding: 6% 8% 4%; */
            padding: 12% 8% 10%;
            @media (min-width: ${breakpoints[1]}px) {
              background-size: 80%;
            }
            &.purple {
              background-image: url(${highlightPurple});
            }
            .contactbox {
              p {
                font-weight: 600;
                margin-left: auto;
                margin-right: auto;
                padding-left: 40px;
                position: relative;
                width: 200px;
                @media (min-width: ${breakpoints[2]}px) {
                  padding-left: 60px;
                }
                &:before {
                  content: "";
                  display: inline-block;
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 30px;
                  height: 30px;
                  @media (min-width: ${breakpoints[2]}px) {
                    width: 40px;
                    height: 40px;
                  }
                }
                &.email {
                  margin-top: 0;
                  @media (min-width: ${breakpoints[2]}px) {
                    margin-top: -1em;
                  }
                  &:before {
                    background-image: url(${letterIcon});
                    background-size: 100%;
                  }
                }
                &.phone:before {
                  background-image: url(${phoneIcon});
                  background-size: 100%;
                }
                a {
                  color: ${theme.bodyColor};
                  display: inline-block;
                  text-decoration: none;
                  transition:  .2s;
                  &:hover {
                    transform: scale(1.05);
                  }
                }
              }
            }
          }
        `}
      />
      <Global
        styles={{
          'h1': {
            textAlign: 'center',
          }
        }}
      />
      <Header siteTitle= {title} location = {location} />
      {children}

      <p className="heading-intro">Ska vi ses?</p>
      <h2>Vill du ha någon att bolla med, någon som hjälper er framåt och blir en
      katalysator för att förverkliga er dröm?</h2>
      <p>Hör av dig så kan vi ta en fika. Kanske kan jag hjälpa dig med precis
      med allt du behöver, kanske kan jag hjälpa dig en bit på vägen.</p>
      <section className="contactbox-container">
        <div className="contactbox">
          <p className="email"><a href="mailto:sofia@konfront.se">sofia@konfront.se</a></p>
          <p className="phone"><a href="tel:+46709798830">0709798830</a></p>
        </div>
      </section>

    </Wrapper>
  );
}
